import * as GQL from 'types/gql.bet';

import * as Operations from './deleteFavoriteSportEvents.bet.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/betting';
const defaultOptions = {} as const;

export type DeleteFavoriteSportEventsMutationFn = Apollo.MutationFunction<
    GQL.DeleteFavoriteSportEvents,
    GQL.DeleteFavoriteSportEventsVariables
>;

/**
 * __useDeleteFavoriteSportEvents__
 *
 * To run a mutation, you first call `useDeleteFavoriteSportEvents` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFavoriteSportEvents` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFavoriteSportEvents, { data, loading, error }] = useDeleteFavoriteSportEvents({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteFavoriteSportEvents(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQL.DeleteFavoriteSportEvents,
        GQL.DeleteFavoriteSportEventsVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        GQL.DeleteFavoriteSportEvents,
        GQL.DeleteFavoriteSportEventsVariables
    >(Operations.DeleteFavoriteSportEvents, options);
}
export type DeleteFavoriteSportEventsHookResult = ReturnType<
    typeof useDeleteFavoriteSportEvents
>;
export type DeleteFavoriteSportEventsMutationResult =
    Apollo.MutationResult<GQL.DeleteFavoriteSportEvents>;
export type DeleteFavoriteSportEventsMutationOptions =
    Apollo.BaseMutationOptions<
        GQL.DeleteFavoriteSportEvents,
        GQL.DeleteFavoriteSportEventsVariables
    >;
