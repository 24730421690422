import { FC } from 'react';
import { useReactiveVar } from '@apollo/client';

import { ACTIVE_SPORTEVENT_STATUSES } from 'app-constants';
import { withClientRender } from 'hocs';
import { useAuthorization } from 'hooks';
import { useBettingLayout } from 'layouts/BettingLayout/BettingLayoutContext';
import { SportEventType } from 'types/gql.bet';
import { favoriteIdsVar, initFavoriteIdsHandler } from './favoriteState';
import { useGetFavoriteSportEventsIds } from './getFavoriteSportEventsIds.bet';

const FavoriteProvider: FC = () => {
    const { sportIds } = useBettingLayout();
    const { isAuthorized } = useAuthorization();

    const favoriteIds = useReactiveVar(favoriteIdsVar);

    useGetFavoriteSportEventsIds(() => ({
        variables: {
            sportIds,
            sportEventTypes: [SportEventType.Match, SportEventType.Outright],
            matchStatuses: ACTIVE_SPORTEVENT_STATUSES,
        },
        fetchPolicy: 'no-cache',
        onCompleted({ favorites }) {
            if (favoriteIds.length === 0 && !!favorites) {
                initFavoriteIdsHandler(favorites);
            }
        },
        skip: !isAuthorized || !!favoriteIds.length,
    }));

    return null;
};

export default withClientRender(FavoriteProvider);
