import { makeVar, useReactiveVar } from '@apollo/client';

import { useRouter } from 'components/HybridRouter';
import { AppLink } from 'types/applink';
import { SportEventType } from 'types/gql.bet';

const resultsAppLinks = [AppLink.RESULTS, AppLink.FAVORITE_RESULTS];

interface FavoriteCount {
    [SportEventType.Match]?: number;
    [SportEventType.Outright]?: number;
}

const initialState: FavoriteCount = {};

export const favoriteIdsVar = makeVar<string[]>([]);
export const favoriteResultIdsVar = makeVar<string[]>([]);

export const favoriteIdsCountVar = makeVar(initialState);

export const setFavoriteIdHandler = (id: string, pathname?: string): void => {
    const stateVar = switchStateVar(pathname);

    stateVar([...stateVar(), id]);
};

export const removeFavoriteIdHandler = (
    id: string,
    pathname?: string
): void => {
    const stateVar = switchStateVar(pathname);

    stateVar(stateVar().filter((favoriteId) => favoriteId !== id));
};

export const initFavoriteIdsHandler = (
    ids: string[],
    pathname?: string
): void => {
    const stateVar = switchStateVar(pathname);

    stateVar(ids);
};

export const useFavoriteState = (): string[] => {
    const { pathname } = useRouter();
    const favoriteResult = useReactiveVar(favoriteResultIdsVar);
    const favoriteIds = useReactiveVar(favoriteIdsVar);

    return resultsAppLinks.includes(pathname as AppLink)
        ? favoriteResult
        : favoriteIds;
};

const switchStateVar = (
    pathname?: string
): typeof favoriteResultIdsVar | typeof favoriteIdsVar => {
    return resultsAppLinks.includes(pathname as AppLink)
        ? favoriteResultIdsVar
        : favoriteIdsVar;
};

export const setFavoriteIdsCountVar = (value: FavoriteCount): void => {
    favoriteIdsCountVar({ ...favoriteIdsCountVar(), ...value });
};

export const setFavoriteIdsCountByTypeVar = (
    delta: 1 | -1,
    type: SportEventType,
    pathname: string
): void => {
    if (resultsAppLinks.includes(pathname as AppLink)) {
        return;
    }

    const favoritesCountByType = favoriteIdsCountVar()[type] || 0;

    favoriteIdsCountVar({
        ...favoriteIdsCountVar(),
        [type]: favoritesCountByType + delta,
    });
};
