export { default } from './FavoriteProvider';
export {
    favoriteIdsCountVar,
    favoriteIdsVar,
    favoriteResultIdsVar,
    initFavoriteIdsHandler,
    removeFavoriteIdHandler,
    setFavoriteIdHandler,
    setFavoriteIdsCountByTypeVar,
    setFavoriteIdsCountVar,
    useFavoriteState,
} from './favoriteState';
