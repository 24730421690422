import React from 'react';

import PackIcon, { IconsPack } from 'components/PackIcon';
import { withClientRender } from 'hocs';
import { Action, Label, useAnalyticAttrs } from 'services/GoogleAnalytics';
import useFavoriteButton from './useFavoriteButton';

interface Props {
    sportEventId: string;
}

const FavoriteButton: React.FC<Props> = ({ sportEventId }) => {
    const {
        isFavorite,
        favoriteStatusHandler,
        unauthenticatedHandler,
        isAuthorized,
    } = useFavoriteButton({ sportEventId });
    const analytic = useAnalyticAttrs({
        defaultAttrs: {
            'data-action': Action.ToggleFavorite,
            'data-label': isFavorite ? Label.Remove : Label.Add,
        },
    });

    return (
        <div
            data-category={analytic['data-category']}
            data-action={analytic['data-action']}
            data-label={analytic['data-label']}
            data-test="favorite-button"
        >
            <PackIcon
                id={isFavorite ? 'favorite-filled' : 'favorite-border'}
                pack={IconsPack.SpriteIcons}
                className="cursor-pointer fill-primary-white"
                onClick={
                    isAuthorized
                        ? favoriteStatusHandler
                        : unauthenticatedHandler
                }
            />
        </div>
    );
};

export default React.memo(
    withClientRender(FavoriteButton, () => {
        return <div className="h-6 w-6" />;
    })
);
