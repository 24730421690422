import * as GQL from 'types/gql.bet';

import * as Operations from './addFavoriteSportEvents.bet.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/betting';
const defaultOptions = {} as const;

export type AddFavoriteSportEventsMutationFn = Apollo.MutationFunction<
    GQL.AddFavoriteSportEvents,
    GQL.AddFavoriteSportEventsVariables
>;

/**
 * __useAddFavoriteSportEvents__
 *
 * To run a mutation, you first call `useAddFavoriteSportEvents` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFavoriteSportEvents` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFavoriteSportEvents, { data, loading, error }] = useAddFavoriteSportEvents({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAddFavoriteSportEvents(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQL.AddFavoriteSportEvents,
        GQL.AddFavoriteSportEventsVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        GQL.AddFavoriteSportEvents,
        GQL.AddFavoriteSportEventsVariables
    >(Operations.AddFavoriteSportEvents, options);
}
export type AddFavoriteSportEventsHookResult = ReturnType<
    typeof useAddFavoriteSportEvents
>;
export type AddFavoriteSportEventsMutationResult =
    Apollo.MutationResult<GQL.AddFavoriteSportEvents>;
export type AddFavoriteSportEventsMutationOptions = Apollo.BaseMutationOptions<
    GQL.AddFavoriteSportEvents,
    GQL.AddFavoriteSportEventsVariables
>;
