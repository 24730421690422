import { MouseEvent, useCallback } from 'react';

import { ModalsHash } from 'app-constants';
import { useRouter } from 'components/HybridRouter';
import useModal from 'components/modals/useModal';
import { useAuthorization } from 'hooks';
import { useBettingApolloClient } from 'services/apollo';
import { MatchBase } from 'types/gql.bet';
import { getSportEventByFragment } from '../Betslip/utils';
import {
    removeFavoriteIdHandler,
    setFavoriteIdHandler,
    setFavoriteIdsCountByTypeVar,
    useFavoriteState,
} from '../Favorites/FavoriteProvider';
import { useAddFavoriteSportEvents } from './addFavoriteSportEvents.bet';
import { useDeleteFavoriteSportEvents } from './deleteFavoriteSportEvents.bet';

interface Input {
    sportEventId: string;
}

interface Output {
    isAuthorized: boolean;
    favoriteStatusHandler: (e: MouseEvent<SVGSVGElement>) => void;
    unauthenticatedHandler: (e: MouseEvent<SVGSVGElement>) => void;
    isFavorite: boolean;
}

const useFavoriteButton = ({ sportEventId }: Input): Output => {
    const { isAuthorized } = useAuthorization();
    const { pathname } = useRouter();
    const favoriteIds = useFavoriteState();
    const { client } = useBettingApolloClient();

    const { openModal } = useModal();

    const isFavorite = favoriteIds.includes(sportEventId);

    const [deleteFavoriteSportEventsMutation] = useDeleteFavoriteSportEvents();
    const [addFavoriteSportEventsMutation] = useAddFavoriteSportEvents();

    const favoriteStatusHandler = useCallback(
        (e: MouseEvent<SVGSVGElement>) => {
            if (!sportEventId) return;

            e.preventDefault();
            e.stopPropagation();

            const variables = {
                ids: [sportEventId],
            };

            const {
                fixture: { type },
            } = getSportEventByFragment(sportEventId, client, [
                'fixture',
            ]) as MatchBase;

            if (isFavorite) {
                return deleteFavoriteSportEventsMutation({ variables }).then(
                    () => {
                        removeFavoriteIdHandler(sportEventId, pathname);
                        setFavoriteIdsCountByTypeVar(-1, type, pathname);
                    }
                );
            }

            return addFavoriteSportEventsMutation({ variables }).then(() => {
                setFavoriteIdHandler(sportEventId, pathname);
                setFavoriteIdsCountByTypeVar(1, type, pathname);
            });
        },
        [
            addFavoriteSportEventsMutation,
            client,
            deleteFavoriteSportEventsMutation,
            isFavorite,
            pathname,
            sportEventId,
        ]
    );

    const unauthenticatedHandler = (e: MouseEvent<SVGSVGElement>) => {
        e.stopPropagation();
        e.preventDefault();

        openModal({ hash: ModalsHash.Authorization });
    };

    return {
        isAuthorized,
        favoriteStatusHandler,
        isFavorite,
        unauthenticatedHandler,
    };
};

export default useFavoriteButton;
